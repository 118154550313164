import React from "react"
import { EstatePlanContainer } from "./janowotwo/janowoTwoStyle"
import { Col, Container, Row } from "react-bootstrap"

const EstatePlan = ({ image }) => (
  <EstatePlanContainer className="plan">
    <Container>
      <Row>
        <Col className="plan-title" xs="12">
          <h1>Plan Osiedla</h1>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row style={{ marginBottom: `55px` }}>
        <Col className="no-padding" xs="12">
          <div className="plan-container">
            <img src={image} alt={`Plan`} style={{ maxWidth: "100%" }} />
          </div>
        </Col>
      </Row>
    </Container>
  </EstatePlanContainer>
)

export default EstatePlan
