import React, { useState } from "react"
import { EstateNavStyled } from "../estatesStyle"
import { Col, Container, Row } from "react-bootstrap"
import icon1 from "../../../images/info.png"
import icon2 from "../../../images/visual.png"
import icon3 from "../../../images/building.png"
import icon4 from "../../../images/location.png"
import icon5 from "../../../images/plan.png"
import icon6 from "../../../images/memo.png"
import { Location } from "@reach/router"
import { Link as Scroller } from "react-scroll"
import Scroll from "react-scroll"
import { injectIntl } from "gatsby-plugin-intl"

const OJP3EstateNav = ({ newproject, scrollDestination, completed, intl }) => {
  const scroller = Scroll.scroller
  const ScrollConfig = {
    spy: true,
    smooth: true,
    offset: 50,
    duration: 500,
  }

  switch (scrollDestination) {
    case "description":
      scroller.scrollTo("description", {
        ScrollConfig,
      })
      break
    case "visual":
      scroller.scrollTo("visual", {
        ScrollConfig,
      })
      break
    case "flats":
      scroller.scrollTo("flats", {
        ScrollConfig,
      })
      break
    case "localization":
      scroller.scrollTo("localization", {
        ScrollConfig,
      })
      break
    default:
  }
  return (
    <EstateNavStyled>
      <Container className="estate-nav-padding" fluid>
        <Row>
          <Col xs={"12"} className={"nav-element-container"}>
            <div className="border-effect">
              <Scroller
                activeClass="active"
                to="description"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                <div className={`nav-element`}>
                  <div className="img-container">
                    <img src={icon1} alt="" />
                  </div>
                  {intl.formatMessage({
                    id: "shared.offer.nav.description",
                  })}
                </div>
              </Scroller>
            </div>
          </Col>

          <Col xs={"12"} className={"nav-element-container"}>
            <div className="border-effect">
              <Scroller
                activeClass="active"
                to="estatemodal"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                <div className={`nav-element`}>
                  <div className="img-container">
                    <img src={icon1} alt="" />
                  </div>
                  Zapytaj o cenę
                </div>
              </Scroller>
            </div>
          </Col>

          <Col xs={"12"} className={"nav-element-container"}>
            <div className="border-effect">
              <Scroller
                activeClass="active"
                to="visual"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                <div className={`nav-element`}>
                  <div className="img-container">
                    <img src={icon2} alt="" />
                  </div>
                  {intl.formatMessage({
                    id:
                      "shared.offer.nav.visual" +
                      (completed ? ".completed" : ""),
                  })}
                </div>
              </Scroller>
            </div>
          </Col>
          <div className={"w-100"}>
            <Row>
              <Col xs={"12"} className={"nav-element-container"}>
                <div className="border-effect">
                  <Scroller
                    activeClass="active"
                    to="flats"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                  >
                    <div className={`nav-element`}>
                      <div className="img-container">
                        <img src={icon3} alt="" />
                      </div>
                      {intl.formatMessage({
                        id: "shared.offer.nav.flats",
                      })}

                    </div>
                  </Scroller>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={"12"} className={"nav-element-container"}>
                <div className="border-effect">
                  <Scroller
                    activeClass="active"
                    to="plan"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                  >
                    <div className={`nav-element`}>
                      <div className="img-container">
                        <img src={icon5} alt="" />
                      </div>
                      {intl.formatMessage({ id: "shared.offer.nav.plan" })}
                    </div>
                  </Scroller>
                </div>
              </Col>
              <Col xs={"12"} className={"nav-element-container"}>
                <div className="border-effect">
                  <Scroller
                    activeClass="active"
                    to="localization"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                  >
                    <div className={`nav-element`}>
                      <div className="img-container">
                        <img src={icon4} alt="" />
                      </div>
                      {intl.formatMessage({
                        id: "shared.offer.nav.localization",
                      })}
                    </div>
                  </Scroller>
                </div>
              </Col>
            </Row>
          </div>
          <div
            className={"w-100"}
            style={newproject ? { opacity: 0.3, pointerEvents: "none" } : {}}
          >
            <Col xs={"12"} className={"nav-element-container"}>
              <div className="border-effect">
                <Scroller
                  activeClass="active"
                  to="diary"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  <div className={`nav-element`}>
                    <div className="img-container">
                      <img src={icon6} alt="" />
                    </div>
                    {completed
                      ? intl.formatMessage({ id: "offer.gallery" })
                      : intl.formatMessage({
                        id: "shared.offer.nav.diary",
                      })}{" "}
                    {newproject
                      ? `- ${intl.formatMessage({
                        id: "shared.offer.nav.soon",
                      })}`
                      : ""}
                  </div>
                </Scroller>
              </div>
            </Col>
          </div>
        </Row>
      </Container>
    </EstateNavStyled>
  )
}

export default injectIntl(OJP3EstateNav)
