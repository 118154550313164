import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import EstateVisualContainer from "@/components/estates/visual/estateVisualContainer"

import wiz_u3 from "@/images/z-20/Janowo_Park_III_ujecie_03.jpg"
import wiz_u4 from "@/images/z-20/Janowo_Park_III_ujecie_04.jpg"
import wiz_u5 from "@/images/z-20/Janowo_Park_III_ujecie_05.jpg"
import wiz_u6 from "@/images/z-20/Janowo_Park_III_ujecie_06.jpg"
import wiz3 from "@/images/wiz5.jpg"
import wiz6 from "@/images/z-7/wiz7.jpg"
import wiz9 from "@/images/z-9/Lazienka2_V03.jpg"
import wiz10 from "@/images/z-9/Lazienka3_V03.jpg"

export default injectIntl(({ intl }) => {
  const sections = [
    //   // TODO move to translations when en translation available
    {
      title: intl.formatMessage({
        id: "offer.janowo.visual.title",
      }),
      items: [
        {
          title: "Dbamy o każdy szczegół",
          text:
            "Osiedle Janowo Park III to nie tylko wyjątkowa lokalizacja na granicy Rumi i&nbsp;Gdyni, lecz również teren zaprojektowany z myślą o najwyższym komforcie użytkowania.",
          img: wiz_u3,
          imgClass: "cover",
        },
        {
          title: "Doświadczony deweloper",
          text:
            "Podczas tworzenia inwestycji uwzględniliśmy nasze wieloletnie doświadczenie oraz opinie Klientów, którzy zaufali nam podczas inwestycji Osiedle Janowo Park oraz Osiedle Janowo Park II. Dzięki takiemu podejściu stworzyliśmy osiedle najwyżej jakości, które sprosta wysokim oczekiwaniom.",
          img: wiz_u4,
          imgClass: "cover",
        },
        {
          title: "Nowoczesne rozwiązania",
          text:
            "Budynek wchodzący w skład Osiedla Janowo Park III będzie wyposażony w cichobieżne windy we wszystkich klatkach rozpoczynające swój bieg od poziomu hali garażowej poprzez przestronne klatki schodowe.",
          img: wiz_u5,
          imgClass: "cover",
        },
        {
          title: "Przestronne strefy wejścia",
          text: `<strong>Przytulna i klasyczna strefa wejścia</strong> utrzymana w stonowanej kolorystyce oraz przestronne korytarze z gustownymi portalami drzwiowymi tworzą elegancki styl.  `,
          img: wiz3,
          imgClass: "cover",
        },
        {
          title: "Duża hala garażowa i miejsca parkingowe zewnętrzne",
          text:
            "Dodatkowym atutem jest duża ilość miejsc postojowych w hali garażowej, komórek lokatorskich oraz miejsc zewnętrznych.",
          img: wiz6,
          imgClass: "cover",
        },
        {
          title: "Części wspólne wysokiej jakości",
          text: `Podczas projektowania inwestycji duży nacisk kładliśmy na <strong>estetykę</strong> oraz <strong>wysoką funkcjonalność</strong> części wspólnych.`, //<br><br><strong>Budynek</strong> wchodzący w skład Osiedla Janowo Park III będzie wyposażony w <strong>cichobieżne windy</strong> we wszystkich klatkach rozpoczynające swój bieg od poziomu hali garażowej poprzez <strong>przestronne klatki schodowe</strong>.<br> Dodatkowym atutem jest duża ilość miejsc postojowych w hali garażowej, komórek lokatorskich oraz miejsc zewnętrznych.
          img: wiz_u6,
          imgClass: "cover",
        },
      ],
    },
    {
      title: "Zmiany aranżacyjne i wykończenia pod klucz",
      items: [
        {
          text: `Wiemy, że wykończenie mieszkania często jest nie lada wyzwaniem, dlatego bazując na wieloletnim doświadczeniu naszych architektów i współpracy ze sprawdzonymi wykonawcami stworzyliśmy dział aranżacji i wykończeń pod klucz.<br><br>Jeśli potrzebujesz wprowadzić zmiany w układzie swojego mieszkania tak aby spełniało Twoje potrzeby, zaraz po zakupie lokalu, wspólnie z naszym architektem możesz dokonać niezbędnych poprawek.`,
          img: wiz9,
        },
        {
          text: `Możesz również skorzystać z pakietów wykończeniowych oszczędzając swój czas i pieniądze. To doskonałe rozwiązanie dzięki któremu zamieszkasz w pięknych i funkcjonalnych wnętrzach. Współpracując z naszym architektem stworzysz przestrzeń spełniającą Twoje oczekiwania.`,
          img: wiz10,
          imgStyle: { marginTop: "-42%" },
        },
      ],
    },
  ]

  return <EstateVisualContainer sections={sections} />
})
