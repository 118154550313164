import React, { useState } from "react"
import { DescriptionStyled, PuckIcons } from "../janowotwo/janowoTwoStyle"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "gatsby-plugin-intl"
import FsLightbox from "fslightbox-react"
import image from "@/images/z-20/Janowo_Park_III_ujecie_02-tablet.jpg"
import imageLarge from "@/images/z-20/Janowo_Park_III_ujecie_02.jpg"
import icon1 from "@/images/z-17/icon-1.png"
import icon2 from "@/images/z-17/icon-2.png"
import icon3 from "@/images/z-17/icon-3.png"
import icon4 from "@/images/z-17/icon-4.png"
import icon5 from "@/images/z-17/icon-5.png"
import icon6 from "@/images/z-17/icon-6.png"

const OJP3Description = ({ intl }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  })

  const icons = [
    {
      icon: icon1,
      title: "Doskonała lokalizacja",
      text: "jedynie 2 km od Gdyni",
    },
    {
      icon: icon2,
      title: "Komunikacja",
      text: "niewielka odległość od komunikacji miejskiej i podmiejskiej – w tym niecałe 2 km od SKM Rumia Janowo",
    },
    {
      icon: icon3,
      title: "PARK BŁONIA JANOWSKIE",
      text: "w pobliżu inwestycji powstaje park Błonia Janowskie"
    },
    {
      icon: icon4,
      title: "PLAŻA W MECHELINKACH",
      text: "odległość od plaży w Mechelinkach to jedynie 7 km",
    },
    {
      icon: icon5,
      title: "ŚCIEŻKI rowerowe",
      text: "w okolicy znajdują się liczne ścieżki rowerowe – zarówno w stronę Trójmiasta, jak i Zatoki Puckiej",
    },
    // {
    //   icon: icon6,
    //   title: "ŚCIEŻKI ROWEROWE",
    //   text: "w okolicy znajdują się liczne ścieżki rowerowe – zarówno w stronę Trójmiasta, jak i Zatoki Puckiej",
    // },
  ]

  return (
    <DescriptionStyled>
      <div className="content description" style={{ padding: 0 }}>
        <Container className="no-padding">
          <Row>
            <Col
              className="no-padding"
              style={{ padding: 0 }}
              xs="12"
              sm="12"
              md="12"
              lg="5"
            >
              <Container style={{ padding: 0, margin: 0 }} fluid>
                <Row>
                  <Col className="title" xs="12">
                    <h2>
                      {intl.formatMessage({
                        id: "offer.janowo.description.title",
                      })}
                    </h2>
                  </Col>
                  <Col xs={12} className={"paragraph"}>
                    <p>
                      Osiedle Janowo Park III powstaje jako nowy rozdział
                      i&nbsp;kontynuacja kameralnego Osiedla Janowo Park oraz
                      nowoczesnego Osiedla Janowo Park II.
                    </p>
                  </Col>
                  <Col xs={12} className={"paragraph"}>
                    <p>
                      Usytuowane niewiele ponad 3 km od dynamicznej Gdyni, a
                      jednocześnie zaledwie 7 km od spokojnych, piaszczystych
                      plaż w Mechelinkach, Osiedle Janowo Park III to idealna
                      przestrzeń do życia dla osób ceniących ciszę i spokój, a
                      także bliskość udogodnień dużego miasta. W najbliższej
                      okolicy znajduje się rozwinięta sieć komunikacyjna i
                      przyjazna dla rodzin infrastruktura szkolno-przedszkolna.
                      Osiedle Janowo Park III - 15 minut od Gdyni!
                    </p>
                  </Col>
                  {/* <Col xs={12} className={"paragraph"}>
                    <p>
                      {intl.formatMessage({
                        id: "offer.janowotwo.description.content.second",
                      })}
                    </p>
                  </Col>*/}
                </Row>

              </Container>
            </Col>
            <Col className="no-padding"
                 xs="12"
                 sm="12"
                 md="12"
                 lg="7"
                 style={{ padding: 0 }}
            >
              <Container
                style={{ padding: 0 }}
                className="vector-image-container"
                fluid
              >
                <Row>
                  <Col xs="12" className={"raster-image"}>
                    <img
                      src={image}
                      alt={"OJP3"}
                      style={{ maxWidth: "100%", cursor: "pointer" }}
                      onClick={() =>
                        setLightboxController({
                          slide: 1,
                          toggler: !lightboxController.toggler,
                        })
                      }
                    />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <PuckIcons style={{ marginBottom: "100px" }}>
            <Row>
              {icons.map(icon => (
                <Col xs="12" md={6} lg={4}>
                  <img src={icon.icon} alt={icon.title} />
                  <div>
                    <h4>{icon.title}</h4>
                    <p>{icon.text}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </PuckIcons>
        </Container>
      </div>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={[imageLarge]}
        slide={lightboxController.slide}
      />
    </DescriptionStyled>
  )
}

export default injectIntl(OJP3Description)
