import { MobileButton } from "@/components/shared/button/button"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const EstateModalForm = ({ title, estate, buttonText, children }) => {
  return (
    <Container className={"estatemodal"}>
      <Row>
        <Col>
          <div
            style={{
              margin: "35px auto 25px",
              maxWidth: "1024px",
              fontSize: "20px",
              fontWeight: 200,
            }}
          >
            {children}
          </div>
          <div style={{ margin: "0 auto 15px", maxWidth: "350px" }}>
            <MobileButton
              handleAction={() => {
                document.getElementById("myModal").style = {
                  display: "block",
                }
                document.getElementsByClassName("code")[0].innerText = title
                document.getElementById("projectName").value = estate
              }}
              innerText={buttonText || "Zapytaj o inwestycję"}
              bg={"#94C835"}
              color={"white"}
              border={"white"}
              arrow={false}
            />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default EstateModalForm
